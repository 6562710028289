import type { AppProps } from "next/app";

import { api } from "../utils/api";

import "../styles/globals.css";
import "styles/Calendar.css";
import "styles/Clock.css";
import "styles/DateTimePicker.css";
import "styles/DatePicker.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import type { Session } from "@supabase/auth-helpers-react";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import type { Database } from "../types/supabase";
import { ToastContainer } from "react-toastify";
import Navbar from "../components/Navbar";
import Footer from "components/Footer";
import { Poppins } from "next/font/google";
import Script from "next/script";
import { useRouter } from "next/router";
import { Routes } from "utils/routes";
import { Transition } from "@headlessui/react";
import Spinner from "components/Spinner";
import * as Sentry from "@sentry/nextjs";

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  style: ["normal", "italic"],
});

const MyApp = ({
  Component,
  pageProps,
}: AppProps<{ initialSession: Session }>) => {
  const [supabaseClient] = useState(() => createPagesBrowserClient<Database>());

  const router = useRouter();

  const [searching, setSearching] = useState(false);

  useEffect(() => {
    router.events.on("routeChangeStart", (route) => {
      if (route.includes("search")) {
        setSearching(true);
      }
    });

    router.events.on("routeChangeComplete", (route) => {
      if (route.includes("search")) {
        setSearching(false);
      }
    });

    supabaseClient.auth.onAuthStateChange(async (event) => {
      switch (event) {
        case "PASSWORD_RECOVERY":
          router.push(Routes.UPDATE_PASSWORD);
          break;
        case "SIGNED_OUT":
          Sentry.setUser(null);
          break;
      }
    });
  }, []);

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${poppins.style.fontFamily};
        }
      `}</style>
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-P5G32XF');`,
        }}
      />
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <div className={`flex min-h-screen flex-col ${poppins.className}`}>
          <Navbar />
          <main className={`flex flex-1 flex-col`}>
            <Transition
              show={searching}
              className={`pointer-events-none absolute inset-0 z-50 flex items-center justify-center bg-black/70 transition-opacity`}
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Spinner className="h-10 w-10 text-theme-blue" />
            </Transition>

            <Component {...pageProps} />
          </main>
          <Footer />
          <ToastContainer position="bottom-center" hideProgressBar={true} />
        </div>
      </SessionContextProvider>
    </>
  );
};

export default api.withTRPC(MyApp);
