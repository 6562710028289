import { Combobox } from "@headlessui/react";
import useDebounce from "hooks/useDebounce";
import React, { Fragment, useEffect, useState } from "react";
import cx from "classnames";
import { api } from "utils/api";
import Spinner from "./Spinner";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import PinIcon from "components/icons/pin.svg";
import SearchIcon from "components/icons/search.svg";
import { getQueryParam } from "utils/query-params";
import { Routes } from "utils/routes";

const CURRENT_LOCATION = "CURRENT_LOCATION";

interface Props {
  children: ({
    setQuery,
  }: {
    setQuery: (query: string) => void;
  }) => React.ReactNode;
}

export default function SearchWrapper(props: Props) {
  const router = useRouter();
  const [location, setLocation] = useState("");
  const [query, setQuery] = useState("");
  const searchTerm = useDebounce(query, 200);
  const [loadingPosition, setLoadingPosition] = useState(false);

  const { data, isFetching } = api.location.lookupLocation.useQuery(
    { searchTerm, specificity: "city" },
    {
      enabled: !!searchTerm,
    }
  );

  useEffect(() => {
    const locationParam = getQueryParam(router.query.location);

    if (locationParam) {
      setLocation(locationParam);
    }
  }, [router]);

  const onSelect = (location: string) => {
    if (location === CURRENT_LOCATION) {
      searchByCurrentLocation();
    } else {
      setLocation(location);

      setTimeout(() => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }, 100);

      router.push(Routes.buildSearchRoute(location));
    }
  };

  const getByLocationMutation = api.location.closestCity.useMutation({
    onSuccess: (location) => {
      if (!location) return;

      onSelect(location);
    },
    onSettled: () => {
      setLoadingPosition(false);
    },
  });

  const searchByCurrentLocation = () => {
    setLoadingPosition(true);
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        getByLocationMutation.mutate({
          lat: coords.latitude,
          long: coords.longitude,
        });
      },
      () => {
        toast.error("Error getting location.");
      }
    );
  };

  return (
    <div className="group flex w-full max-w-lg flex-col">
      <Combobox value={location} onChange={onSelect}>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (!location) return;
            onSelect(location);
          }}
        >
          <label
            htmlFor="search"
            className="relative flex h-14 w-full items-center rounded-full"
          >
            <div className="opacity-70">
              <SearchIcon />
            </div>
            {props.children({ setQuery })}

            <div className="absolute top-16 z-10 hidden w-full overflow-hidden rounded border bg-white text-black shadow-xl group-focus-within:block">
              <Combobox.Options static>
                <Combobox.Option as={Fragment} value={CURRENT_LOCATION}>
                  <li
                    className={`flex w-full cursor-pointer items-center justify-between rounded-t  border-2 border-transparent py-4 px-2 text-left transition-opacity hover:border-theme-blue focus:border-theme-blue ${cx(
                      { "rounded-b": !data || !data?.length }
                    )}`}
                  >
                    <span className="flex items-center">
                      <span className="mr-2 font-semibold">
                        Current location
                      </span>{" "}
                      <PinIcon />
                    </span>
                    {loadingPosition && <Spinner className="h-7 w-7" />}
                  </li>
                </Combobox.Option>
                {isFetching && <Spinner className="mx-auto my-4" />}
                {data?.map((location) => (
                  <Combobox.Option
                    as={Fragment}
                    key={JSON.stringify(location)}
                    value={location.description}
                  >
                    <li
                      className={`block cursor-pointer border-2 py-4 px-2 transition-opacity last-of-type:rounded-b ui-active:border-theme-blue ui-not-active:border-transparent`}
                    >
                      {location.description}
                    </li>
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </div>
          </label>
        </form>
      </Combobox>
    </div>
  );
}
