import { AccountPlan } from "@prisma/client";

const dev = process.env.NODE_ENV === "development";
const test =
  process.env.VERCEL_ENV === "preview" ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === "preview";

export const DOMAINS = {
  PROD: "https://www.pipersforhire.com",
  TEST: "https://test.pipersforhire.com",
  DEV: "http://localhost:3000",
};

export const CURRENT_DOMAIN = dev
  ? DOMAINS.DEV
  : test
  ? DOMAINS.TEST
  : DOMAINS.PROD;

export const STRIPE_PRICE_IDS: Record<Exclude<AccountPlan, "FREE">, string> = {
  PRO:
    dev || test
      ? "price_1MKiI2AGBcplZrnxhbVoMQ7v"
      : "price_1McbCCAGBcplZrnxUhnZmYn1",
};

export const STRIPE_PRODUCTS: Record<Exclude<AccountPlan, "FREE">, string> = {
  PRO: dev || test ? "prod_N4s2KcKrbteQJA" : "prod_NNLuxSEJFnxLlw",
};

export const STRIPE_PRICE_ID_TO_PLAN_MAP: Record<
  string,
  Exclude<AccountPlan, "FREE">
> = {
  price_1MKiI2AGBcplZrnxhbVoMQ7v: "PRO",
  price_1McbCCAGBcplZrnxUhnZmYn1: "PRO",
};

export const STORAGE_FOLDERS = {
  PROFILE_IMAGES: "profile-images",
  REVIEW_IMAGES: "review-images",
  AUDIO_FILES: "audio-files",
  VIDEO_FILES: "video-files",
};

export const PAYMENT_CUT = 0.05;

const domain = "@pipersforhire.com";

export const EMAILS = {
  CONTACT: `contact${domain}`,
  CONTACT_FORM: `contactform${domain}`,
  NO_REPLY: `no-reply${domain}`,
} as const;

export const LOCAL_STORAGE = {
  CLIENT_VIEW: "client_view",
};

export const FREE_USER_IMAGE_LIMIT = 5;
export const IMAGE_LIMIT = 10;

export const ADMIN_USERS = [
  "johnsonrobertandrew@gmail.com",
  "andrew@pipersforhire.com",
  "jonas@pipersforhire.com",
  "bookings@piperjonas.com",
  "andrewj@hey.com",
];
