import Link from "next/link";
import React from "react";
import { Routes } from "utils/routes";

export default function Footer() {
  return (
    <footer className="flex flex-col items-center justify-center bg-theme-dark-blue px-3 pb-6 pt-10 text-white/70">
      <div className="mx-auto flex w-full max-w-screen-xl items-center justify-center">
        <section>
          <div className="font-semibold text-white">Company</div>

          <ul className="mt-4 flex flex-col space-y-4 text-sm">
            <Link href={Routes.ABOUT}>About Us</Link>
            <Link href={Routes.FAQS}>FAQs</Link>
          </ul>
        </section>

        <section className="ml-24">
          <div className="font-semibold text-white">Resources</div>

          <ul className="mt-4 flex flex-col space-y-4 text-sm">
            <Link href={Routes.HIRING_GUIDE}>Hiring Guide</Link>
            <Link href={Routes.CONTACT}>Contact Us</Link>
          </ul>
        </section>
      </div>

      <div className="mt-14 flex flex-col items-center space-y-4 text-sm sm:flex-row sm:space-y-0">
        <div>&copy; Pipers For Hire {new Date().getFullYear()} </div>
        <div className="mx-4 hidden h-4 w-px bg-white/70 sm:block" />
        <Link href={Routes.PRIVACY_POLICY}>Privacy Policy</Link>
        <div className="mx-4 hidden h-4 w-px bg-white/70 sm:block" />
        <Link href={Routes.TERMS_OF_USE}>Terms of Use</Link>
      </div>
    </footer>
  );
}
