import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useLocalStorage } from "react-use";
import { LOCAL_STORAGE } from "utils/constants";

const clientViewAtom = atom(
  typeof window !== "undefined"
    ? localStorage.getItem(LOCAL_STORAGE.CLIENT_VIEW)
    : false
);

const useClientView = () => {
  const [state, setState] = useAtom(clientViewAtom);

  const [clientView, setClientView] = useLocalStorage(
    LOCAL_STORAGE.CLIENT_VIEW,
    false
  );

  useEffect(() => {
    if (typeof clientView !== "undefined") {
      setState(clientView);
    }
  }, [clientView, setState]);

  return [!!state, setClientView] as const;
};

export default useClientView;
