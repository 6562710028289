import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useUser } from "@supabase/auth-helpers-react";
import Image from "next/image";
import Link from "next/link";
import React, { Fragment, useState } from "react";
import { Routes } from "../utils/routes";
import NavbarSearchBox from "./NavbarSearchBox";
import Logo from "./logo.png";
import { api } from "utils/api";
import useClientView from "hooks/useClientView";

export default function Navbar() {
  const user = useUser();
  const [client] = useClientView();
  const { data: unreadQuotes } = api.quotes.unread.useQuery(
    { client },
    { enabled: !!user }
  );

  return (
    <div className="relative flex flex-col bg-theme-dark-blue py-4 px-6 pb-0 text-white sm:flex-row sm:py-4">
      <div className="flex w-full items-center justify-between">
        <Link href={Routes.HOME} className="mr-6 w-40 whitespace-nowrap">
          {/* <Logo /> */}
          <Image src={Logo} alt="Pipers For Hire" />
        </Link>

        <div className="hidden w-full sm:flex">
          <div className="flex w-full border-x border-gray-400/50 pl-6">
            <NavbarSearchBox />
          </div>
          <div className="ml-6 flex items-center space-x-6">
            <Link href={Routes.HIRING_GUIDE} className="whitespace-nowrap">
              Hiring Guide
            </Link>
            {user ? (
              <Link
                href={Routes.PROFILE}
                className="relative rounded bg-theme-blue px-4 py-3 text-white transition-all hover:brightness-90"
              >
                Dashboard
                {!!unreadQuotes?.length && (
                  <div className="absolute -top-2 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-gray-300 text-xs text-black shadow">
                    {unreadQuotes.length}
                  </div>
                )}
              </Link>
            ) : (
              <Link
                href={Routes.LOGIN}
                className="whitespace-nowrap rounded bg-theme-blue px-4 py-3 text-white transition-all hover:brightness-90"
              >
                Log In
              </Link>
            )}
          </div>
        </div>
        <MobileMenu unreadCount={unreadQuotes?.length} />
      </div>
      <div className="flex w-full sm:hidden">
        <NavbarSearchBox />
      </div>
    </div>
  );
}

const MobileMenu = ({ unreadCount }: { unreadCount: number | undefined }) => {
  const [open, setOpen] = useState(false);

  const user = useUser();

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="relative block sm:hidden"
      >
        <Bars3Icon className="h-6 w-6" />
        {!!unreadCount && (
          <div className="absolute -top-1.5 -right-1.5 flex h-4 w-4 items-center justify-center rounded-full bg-theme-blue text-xs text-white shadow">
            {unreadCount}
          </div>
        )}
      </button>
      <Transition show={open} as={Fragment}>
        <Dialog onClose={() => setOpen(false)}>
          <Transition.Child
            className="fixed inset-0 bg-black/50 transition-all ease-in-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          />

          <Transition.Child
            as={Fragment}
            enter="transition-transform ease-in-out"
            enterFrom="translate-x-full"
            enterTo="translate-x-none"
            leave="transition-transform ease-in-out"
            leaveFrom="translate-x-none"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="fixed inset-y-0 right-0 w-full max-w-xs bg-white px-3 py-2">
              <button type="button" onClick={() => setOpen(false)}>
                <XMarkIcon className="h-6 w-6" />
              </button>

              <div className="flex flex-col items-center space-y-4">
                <Link
                  href={Routes.HIRING_GUIDE}
                  className="whitespace-nowrap"
                  onClick={() => setOpen(false)}
                >
                  Hiring Guide
                </Link>
                {user ? (
                  <Link
                    href={Routes.PROFILE}
                    className="relative w-full rounded bg-theme-blue px-4 py-3 text-center text-white transition-all hover:brightness-90"
                    onClick={() => setOpen(false)}
                  >
                    Dashboard
                    {!!unreadCount && (
                      <div className="absolute -top-2 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-gray-300 text-xs text-black shadow">
                        {unreadCount}
                      </div>
                    )}
                  </Link>
                ) : (
                  <Link
                    href={Routes.LOGIN}
                    className="w-full whitespace-nowrap rounded bg-theme-blue px-4 py-3 text-center text-white transition-all hover:brightness-90"
                    onClick={() => setOpen(false)}
                  >
                    Log In
                  </Link>
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
