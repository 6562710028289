export const ROUTES = {
  PROFILE: "/dashboard/profile",
  EDIT_PROFILE: "/dashboard/edit-profile",
  SETTINGS: "/dashboard/settings",
  REVIEWS: "/dashboard/reviews",
  INBOX: "/dashboard/inbox",
  UPGRADE: "/dashboard/upgrade",
  ADMIN: "/dashboard/admin",
  LOGIN: "/login",
  JOIN: "/join",
  HOME: "/",
  RESET_PASSWORD: "/reset-password",
  UPDATE_PASSWORD: "/update-password",
  ABOUT: "/about",
  FAQS: "/faqs",
  TERMS_OF_USE: "/terms",
  PRIVACY_POLICY: "/privacy",
  CONTACT: "/contact",
  HIRING_GUIDE: "/hiring-a-bagpiper",
} as const;

export const Routes = {
  ...ROUTES,

  // Helpers
  buildSearchRoute: <T extends string>(location: T) => {
    return `/search/${encodeURIComponent(location)}` as const;
  },

  buildProfileRoute: <T extends number>(profileId: T, name: string) => {
    return `/bagpiper/${profileId}/piper-${name
      .split(" ")[0]
      ?.toLowerCase()}` as const;
  },

  buildQuoteRoute: <T extends number>(quoteId: T) => {
    return `/quotes/${quoteId}` as const;
  },

  buildReviewRoute: <T extends number>(profileId: T) => {
    return `/leave-review/${profileId}` as const;
  },
};
