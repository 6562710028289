import { Combobox } from "@headlessui/react";
import React from "react";
import SearchWrapper from "./SearchWrapper";

export default function NavbarSearchBox() {
  return (
    <SearchWrapper>
      {({ setQuery }) => (
        <Combobox.Input
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            e.target.select();
          }}
          id="search"
          onChange={(event) => setQuery(event.target.value)}
          autoComplete="off"
          placeholder="Location of event"
          className="flex h-full w-full items-center rounded-l-full bg-transparent py-2 pl-3 placeholder:text-white/70 focus-within:outline-none"
        />
      )}
    </SearchWrapper>
  );
}
